import React from 'react';

import SectionTitle from '../section-title/SectionTitle';
import styles from './OrganizationsSection.module.scss';

interface Props {
  title: string;
  organizations: string[];
}

const OrganizationsSection: React.FC<Props> = ({ title, organizations }: Props) => {
  const orgPerColumn = Math.floor(organizations.length / 3);

  return (
    <section className={styles.section}>
      <div className={styles.innerContainer}>
        <SectionTitle title={title} withLine={true} />
        <ul className={styles.organizationsContainer}>
          <div>
            {organizations.slice(0, orgPerColumn + 1).map((p, i) => (
              <li key={`organization-${i}`}>{p}</li>
            ))}
          </div>
          <div>
            {organizations.slice(orgPerColumn + 1, orgPerColumn * 2 + 1).map((p, i) => (
              <li key={`organization-${i}`}>{p}</li>
            ))}
          </div>
          <div>
            {organizations.slice(orgPerColumn * 2 + 1).map((p, i) => (
              <li key={`organization-${i}`}>{p}</li>
            ))}
          </div>
        </ul>
      </div>
    </section>
  );
};

export default OrganizationsSection;
