import React from 'react';

import styles from './OffsetBorderContainer.module.scss';

interface Props {
  children: React.ReactNode;
  offset?: string;
}

const OffsetBorderContainer: React.FC<Props> = ({ children, offset }: Props) => (
  <div className={styles.offsetBorderContainer}>
    <div className={styles.inner} style={{ marginBottom: offset, marginRight: offset }}>
      <div className={styles.border} style={{ top: offset, left: offset }}></div>
      {children}
    </div>
  </div>
);

export default OffsetBorderContainer;
