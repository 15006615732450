import React from 'react';

import OrganizationsSection from '../components/about/organizations-section/OrganizationsSection';
import PromiseSection from '../components/about/promise-section/PromiseSection';
import SectionTitle from '../components/about/section-title/SectionTitle';
import StonemastersSection from '../components/about/stonemasters-section/StonemastersSection';
import YearStoryList from '../components/about/year-story-list/YearStoryList';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Highlights from '../components/shared/highlights/Highlights';
import useViewport from '../hooks/useViewport';
import styles from './about.module.scss';
import { graphql } from 'gatsby';
import { StrapiImage } from '../components/shared/interfaces';

export const query = graphql`
  query {
    strapi {
      aboutPage {
        buyerDescription
        buyerTitle
        description
        organizationsTitle
        presidentDescription
        presidentTitle
        promiseTitle
        stonemastersMobilTitle
        stonemastersStory
        stonemastersTitle
        stonemastersTitleLeft
        stonemastersTitleRight
        subtitle
        title
        stonemastersImg {
          url
        }
        stonemastersMobileImg {
          url
        }
        collageImage {
          url
        }
      }
      aboutPageOrganizations {
        name
      }
      aboutPagePromisePosts {
        text
        title
        image {
          url
        }
      }
      aboutPageYearStories {
        text
        year
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      aboutPage: {
        buyerDescription: string;
        buyerTitle: string;
        description: string;
        organizationsTitle: string;
        presidentDescription: string;
        presidentTitle: string;
        promiseTitle: string;
        stonemastersMobilTitle: string;
        stonemastersStory: string;
        stonemastersTitle: string;
        stonemastersTitleLeft: string;
        stonemastersTitleRight: string;
        subtitle: string;
        title: string;
        stonemastersImg: StrapiImage;
        stonemastersMobileImg: StrapiImage;
        collageImage: StrapiImage;
      };
      aboutPageOrganizations: Array<{
        name: string;
      }>;
      aboutPagePromisePosts: Array<{
        text: string;
        title: string;
        image: StrapiImage;
      }>;
      aboutPageYearStories: Array<{
        text: string;
        year: string;
      }>;
    };
  };
}

const About: React.FC<Props> = ({ data: { strapi: data } }: Props) => {
  const viewport = useViewport();

  const yearStories = data.aboutPageYearStories;

  return (
    <Layout lightNavbar={true}>
      <SEO title={data.aboutPage.title} />
      <section className={styles.topSection}>
        <div className={styles.darkContainer}>
          <Highlights
            title={data.aboutPage.title}
            description={data.aboutPage.description}
            subtitle={data.aboutPage.subtitle}
            onDark={true}
          />
        </div>
        <div className={styles.collageContainer}>
          <img src={data.aboutPage.collageImage.url} alt="collage image" />
        </div>
      </section>
      <PromiseSection title={data.aboutPage.promiseTitle} posts={data.aboutPagePromisePosts} />
      <section className={styles.yearsSection}>
        <SectionTitle title="15 years and growing" />
        <YearStoryList list={yearStories.slice(0, viewport.isTablet ? undefined : 5)} />
        {!viewport.isTablet && <YearStoryList list={yearStories.slice(5)} />}
      </section>
      <StonemastersSection {...data.aboutPage} />
      <OrganizationsSection
        title={data.aboutPage.organizationsTitle}
        organizations={data.aboutPageOrganizations.map((e) => e.name)}
      />
    </Layout>
  );
};

export default About;
