import classnames from 'classnames';
import React from 'react';

import OffsetBorderContainer from '../../shared/offset-border-container/OffsetBorderContainer';
import styles from './PromisePost.module.scss';

interface Props {
  imgSrc: string;
  title: string;
  text: string;
}

const PromisePost: React.FC<Props> = ({ imgSrc, text, title }: Props) => {
  return (
    <article className={styles.post}>
      <h3 className={classnames(styles.title, 'hide-on-desktop')}>{title}</h3>
      <OffsetBorderContainer>
        <img className={styles.image} src={imgSrc} alt="post image" />
      </OffsetBorderContainer>
      <div className={styles.contentBox}>
        <h3 className={classnames(styles.title, 'hide-on-tablet')}>{title}</h3>
        <p className={styles.content}>{text}</p>
      </div>
    </article>
  );
};

export default PromisePost;
