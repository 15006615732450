import React from 'react';
import styles from './PromiseSection.module.scss';
import SectionTitle from '../section-title/SectionTitle';
import PromisePost from '../promise-post/PromisePost';

interface Props {
  title: string;
  posts: Array<{ image: { url: string }; title: string; text: string }>;
}

const PromiseSection: React.FC<Props> = ({ title, posts }: Props) => {
  return (
    <section className={styles.promiseSection}>
      <SectionTitle title={title} />
      {posts.map((p, i) => (
        <PromisePost {...p} imgSrc={p.image?.url} key={`promise-post-${i}`} />
      ))}
    </section>
  );
};

export default PromiseSection;
