import classnames from 'classnames';
import React from 'react';

import styles from './SectionTitle.module.scss';

interface Props {
  title: string;
  mobileTitle?: string;
  withLine?: boolean;
  left?: string;
  right?: string;
  color?: string;
  fontSize?: string;
  uppercase?: boolean;
}

const SectionTitle: React.FC<Props> = ({
  title,
  left,
  right,
  withLine,
  mobileTitle,
  color = '#000',
  uppercase = true,
  fontSize = '2rem',
}: Props) => {
  return (
    <div className={styles.sectionTitle}>
      <div className={classnames(styles.sideCaption, 'hide-on-tablet')}>
        {left && <div className={styles.caption}>{left}</div>}
        {withLine && <div className={styles.line + (left ? '' : ` ${styles.noLeftMargin}`)}></div>}
      </div>
      <h2
        className={classnames(uppercase && styles.uppercase, 'hide-on-tablet')}
        style={{ color, fontSize }}
      >
        {title}
      </h2>
      <h2
        className={classnames(uppercase && styles.uppercase, 'hide-on-desktop')}
        style={{ color, fontSize }}
      >
        {mobileTitle || title}
      </h2>
      <div className={classnames(styles.sideCaption, 'hide-on-tablet')}>
        {withLine && (
          <div className={styles.line + (right ? '' : ` ${styles.noRightMargin}`)}></div>
        )}
        {right && <div className={styles.caption}>{right}</div>}
      </div>
    </div>
  );
};

export default SectionTitle;
