import React from 'react';

import styles from './YearStory.module.scss';

interface Props {
  year: string;
  text: string;
  isFirst?: boolean;
  isLast?: boolean;
}

const YearStory: React.FC<Props> = ({ text, year, isFirst, isLast }: Props) => {
  return (
    <div className={styles.yearStoryContainer}>
      {!isFirst && <div className={`${styles.circleLink} ${styles.left}`}></div>}
      {!isLast && <div className={`${styles.circleLink} ${styles.right}`}></div>}
      <div className={styles.innerContainerBg}></div>
      <div className={styles.circleContainer}>
        <div className={styles.innerCircle}>{year}</div>
      </div>
      <p>{text}</p>
    </div>
  );
};

export default YearStory;
