import classnames from 'classnames';
import React from 'react';

import YearStory from '../year-story/YearStory';
import styles from './YearStoryList.module.scss';

interface YearStory {
  year: string;
  text: string;
}

interface Props {
  list: YearStory[];
}

const YearStoryList: React.FC<Props> = ({ list }: Props) => {
  return (
    <div>
      <div className={styles.yearsListScroll}>
        <div className={styles.yearsList}>
          {list.map((p, i, array) => (
            <YearStory
              {...p}
              key={`year-story-${i}`}
              isFirst={i === 0}
              isLast={i === array.length - 1}
            ></YearStory>
          ))}
        </div>
      </div>

      <div className={classnames(styles.swipeHint, 'hide-on-desktop')}>
        <span>swipe to see more</span>
        <i></i>
      </div>
    </div>
  );
};

export default YearStoryList;
