import React from 'react';
import styles from './StonemastersSection.module.scss';
import useViewport from '../../../hooks/useViewport';
import SectionTitle from '../section-title/SectionTitle';

interface Props {
  buyerDescription: string;
  buyerTitle: string;
  description: string;
  organizationsTitle: string;
  presidentDescription: string;
  presidentTitle: string;
  promiseTitle: string;
  stonemastersMobilTitle: string;
  stonemastersStory: string;
  stonemastersTitle: string;
  stonemastersTitleLeft: string;
  stonemastersTitleRight: string;
  stonemastersImg: {
    url: string;
  };
  stonemastersMobileImg: {
    url: string;
  };
  collageImage: {
    url: string;
  };
}

const StonemastersSection: React.FC<Props> = ({
  stonemastersTitle,
  stonemastersMobilTitle,
  stonemastersTitleLeft,
  stonemastersTitleRight,
  stonemastersStory,
  buyerTitle,
  buyerDescription,
  presidentTitle,
  presidentDescription,
  stonemastersImg,
  stonemastersMobileImg,
}: Props) => {
  const { isTablet } = useViewport();

  return (
    <section className={styles.stonemastersSection}>
      <div className={styles.stonemastersOuter}>
        <SectionTitle
          title={stonemastersTitle}
          mobileTitle={stonemastersMobilTitle}
          left={stonemastersTitleLeft}
          right={stonemastersTitleRight}
          color={isTablet ? '#fff' : '#000'}
          withLine={true}
        />
        <div className={styles.stonemastersContainer}>
          <p className={styles.center}>{stonemastersStory}</p>
          <img
            src={isTablet ? stonemastersMobileImg.url : stonemastersImg.url}
            alt="stonemasters image"
          />
          <div className={styles.bottomContainer}>
            <div className={styles.post}>
              <h3>{buyerTitle}</h3>
              <p>{buyerDescription}</p>
            </div>
            <div className={styles.post}>
              <h3>{presidentTitle}</h3>
              <p>{presidentDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StonemastersSection;
