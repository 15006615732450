import React from 'react';

import styles from './Highlights.module.scss';
import { SlugType } from '../../../templates/slug-type.interface';
import { Link } from 'gatsby';
import useViewport from '../../../hooks/useViewport';
import iconWhiteMobile from '../../../images/Mobile_White.svg';
import iconWhiteDesktop from '../../../images/Desktop_White.svg';

interface Props {
  title: string;
  subtitle: string;
  description: string;
  onDark?: boolean;
  subtitleWidth?: string;
  textColor?: string;
  hideDelimiter?: boolean;
  fontFamily?: string;
  addClassName?: string;
  fontWeight?: string;
  isDecorative?: boolean;
  isOutdoor?: boolean;
}

const Highlights: React.FC<Props> = ({
  title,
  description,
  subtitle,
  onDark,
  subtitleWidth,
  textColor,
  hideDelimiter,
  fontFamily,
  addClassName,
  fontWeight,
  isDecorative,
  isOutdoor,
}: Props) => {
  const { isTablet } = useViewport();
  const whiteIcon = isTablet ? iconWhiteMobile : iconWhiteDesktop;

  return (
    <section
      className={
        styles.Highlights +
        (onDark ? '' : ` ${styles.onLight}`) +
        ' ' +
        (addClassName ? addClassName : '')
      }
    >
      <h1>{title}</h1>
      <div className={styles.delimiter} style={{ display: hideDelimiter ? 'none' : 'block' }}></div>
      <h2
        className={styles.subtitle}
        style={{
          width: subtitleWidth,
          color: textColor,
          fontFamily: fontFamily ? fontFamily : '',
          fontWeight: fontWeight ? fontWeight : '',
        }}
      >
        {subtitle}
      </h2>
      <p
        style={{
          fontFamily: fontFamily ? fontFamily : '',
          fontWeight: fontWeight ? fontWeight : '',
        }}
      >
        {description}
      </p>
      {isDecorative && (
        <Link to="/portfolio/gallery/Tile%20Inspiration" className={styles.heroGalleryLink}>
          <span>see our tile inspiration gallery</span>
          <img src={whiteIcon} alt="see gallery icon" />
        </Link>
      )}
      {isOutdoor && (
        <Link
          to="/portfolio/gallery/Outdoor"
          className={styles.heroGalleryLink}
          style={{ background: 'rgba(0,0,0, .3)' }}
        >
          <span>see our outdoor gallery</span>
          <img src={whiteIcon} alt="see gallery icon" />
        </Link>
      )}
    </section>
  );
};

export default Highlights;
